import React from 'react';
import {block, Panel} from 'searchkit'

export class SkPanel extends Panel {
    UNSAFE_componentWillReceiveProps(nextProps) {}

    render() {
        const {title, mod, className, disabled, children, collapsable} = this.props;
        const collapsed = collapsable && this.state.collapsed;
        const containerBlock = block(mod).state({disabled});

        let titleDiv;
        if (collapsable) {
            titleDiv = (
                <div>
                    <div
                        className={containerBlock.el('header').state({collapsable, collapsed})}
                        onClick={this.toggleCollapsed.bind(this)}>
                        <div dangerouslySetInnerHTML={{__html: title}}/>
                        <div className={containerBlock.el('header-arrow')}/>
                    </div>

                </div>
            )
        } else {
            titleDiv = <div className={containerBlock.el('header')} dangerouslySetInnerHTML={{__html: title}}/>
        }

        return (
            <div className={containerBlock.mix(className)}>
                {titleDiv}
                <div className={containerBlock.el('content').state({collapsed})}>{children}</div>
            </div>
        )
    }
}
