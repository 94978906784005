/**
 * @return {string}
 */
export function ImageUrl($name) {
    return window.location.origin + (window.location.pathname.includes('olp-website') ? '/olp-website/public' : '') + `/uploads/${$name}`;
}

/**
 * @return {string}
 */
export function IconUrl($name) {
    return window.location.origin + (window.location.pathname.includes('olp-website') ? '/olp-website/public' : '') + `/assets/images/${$name}`;
}

/**
 * @return {string}
 */
export function AddWindowUrlParams($url) {
    return window.location.origin + (window.location.pathname.includes('olp-website') ? '/olp-website/public' : '') + `${$url}` + window.location.search;
}

/**
 * @return {string}
 */
export function ProductImageUrl($name) {
    return window.location.origin + (window.location.pathname.includes('olp-website') ? '/olp-website/public' : '') + `/${$name}`;
}
