import {RangeComponent, RangeHistogram, RangeSlider} from 'searchkit';
import React from "react";
import {SKRangeInput} from "./SKRangeInput";

export class SKRangeComponents extends RangeComponent {
    render() {
        const props = this.props
        const { showHistogram, showSlider, showInput } = props
        return (
            <div>
                {showHistogram ? <RangeHistogram {...props} /> : undefined}
                {showSlider ? <RangeSlider {...props} /> : undefined}
                {showInput ? <SKRangeInput {...props} /> : undefined}
            </div>
        )
    }
}

export function SKRangeComponentBuilder(components) {
    return (props) => <SKRangeComponents {...props} {...components} />
}

export const SKRangeSliderHistogram = SKRangeComponentBuilder({ showHistogram: true, showSlider: true })
export const SKRangeSliderHistogramInput = SKRangeComponentBuilder({
    showHistogram: true,
    showSlider: true,
    showInput: true
})
export const SKRangeSliderInput = SKRangeComponentBuilder({ showSlider: true, showInput: true })
export const SKRangeHistogramInput = SKRangeComponentBuilder({ showHistogram: true, showInput: true })


