import React, {useState} from 'react';
import {IconUrl} from '../Helpers/Url';

let classNames = require('classnames');

export const ArticleTile = ({labels, result}) => {
    const [isHover, setIsHover] = useState( false);
    const projectTileClass = classNames('product-tile', 'product-tile__link', {'is-hover' : isHover});

    return (
        <div className="col-12 col-phablet-6 col-tablet-wide-4 col-tv-3 mb-30">
            <a href={result._source.App_Models_Article_url}
               className={projectTileClass}
               onMouseEnter={() => setIsHover(true)}
               onMouseLeave={() => setIsHover(false)}
            >
                <div className="product-tile__hover">
                    <div className="card-square">
                        <div className="card-square__body card-square__body--center product-tile__image border-s_grey">
                            <img src={
                                result._source.App_Models_Article_article_image
                                    ? result._source.App_Models_Article_article_image
                                    : IconUrl('no-camera-icon.jpg') } className={ result._source.App_Models_Article_article_image ? '' : 'no-image' } alt=""/>
                        </div>
                    </div>

                    <div className="product-tile__title p-15 text-white font-weight-bold">
                        {result._source.App_Models_Article_short_article_name}
                    </div>

                    <div className="show-on-hover">
                        <div className="bg-l_grey py-10 px-15">
                            <div className="row row--5 align-items-end">
                                <div className="col-4">
                                    <div className="product-specs">
                                        <div className="product-specs__title">
                                            <div>{labels.height}</div>
                                            <div>{labels.thickness}</div>
                                        </div>
                                        <div className="product-specs__sub">
                                            {result._source.App_Models_Article_height_article_cm} cm
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="product-specs">
                                        <div className="product-specs__title">
                                            {labels.width}
                                        </div>
                                        <div className="product-specs__sub">
                                            {result._source.App_Models_Article_width_article_cm} cm
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="product-specs">
                                        <div className="product-specs__title">
                                            <div>{labels.depth}</div>
                                            <div>{labels.length}</div>
                                        </div>
                                        <div className="product-specs__sub">
                                            {result._source.App_Models_Article_depth_article_cm} cm
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    )
};
