import {RangeInput, block, NumberInput} from 'searchkit';
import React from 'react';
import {IconUrl} from '../Helpers/Url';

export class SKRangeInput extends RangeInput {
    render() {
        const {
            mod,
            className,
            minValue,
            maxValue,
            translate,
            minPlaceholder,
            maxPlaceholder
        } = this.props

        const bemBlocks = {
            container: block(mod).el
        }

        return (
            <form className={bemBlocks.container().mix(className)} onSubmit={this.handleSubmit}>
                <NumberInput
                    ref="min"
                    className={bemBlocks.container('input')}
                    value={minValue}
                    field="min"
                    placeholder={translate('range.min') || minPlaceholder}
                />
                <div className={bemBlocks.container('to-label')}>tot</div>
                <NumberInput
                    ref="max"
                    className={bemBlocks.container('input')}
                    value={maxValue}
                    field="max"
                    placeholder={translate('range.max') || maxPlaceholder}
                />
                <button type="submit" className={bemBlocks.container('submit')}>
                    <img src={IconUrl('arrow-right.svg')} alt="" />
                </button>
            </form>
        )
    }
}
