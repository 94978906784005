import {FacetFilter, renderComponent} from 'searchkit';

export class RefinementListFilter extends FacetFilter {
    render() {
        if (!this.accessor) return null;
        const {listComponent, containerComponent, showCount, title, id, countFormatter, collapseTrigger} = this.props;

        let size = 999;
        if (this.getItems().length > 4) { // doesn't work, got to overwrite the renderShowMore with this statement built in
            size =  5;
        }

        return renderComponent(
            containerComponent,
            {
                title: `${title}`,
                className: id ? `filter--${id}` : undefined,
                disabled: !this.hasOptions(),
            },
            [
                renderComponent(listComponent, {
                    key: 'listComponent',
                    items: this.getItems(),
                    itemComponent: this.props.itemComponent,
                    selectedItems: this.getSelectedItems(),
                    toggleItem: this.toggleFilter.bind(this),
                    setItems: this.setFilters.bind(this),
                    docCount: this.accessor.getDocCount(),
                    showCount,
                    translate: this.translate,
                    countFormatter
                }),
                this.renderShowMore()
            ]
        )
    }
}

