import React, {useEffect, useRef, useState} from 'react';
import {
    BoolMust,
    Hits,
    HitsStats,
    RangeFilter,
    SearchkitManager,
    SearchkitProvider,
    SideBar,
    TermsQuery
} from "searchkit";

import {ArticleTile} from "../Components/ArticleTile";
import {RefinementListFilter} from "../Components/RefinementListFilter";
import {SkPanel} from "../Components/Panel";
import {HideOnInitialLoad} from "../Components/HideOnInitialLoad";
import {SKRangeSliderHistogramInput} from "../Components/SKRangeComponents";
import {SKPagination} from "../Components/SKPagination";

let _foreach = require('lodash/foreach');
let _map = require('lodash/map');

export default function App(props) {
    const language = props.language;
    const category = props.category;
    const subCategories = JSON.parse(props.subcategories);
    const filters = JSON.parse(props.properties);
    const rangeSliderFields = JSON.parse(props.rangeslider);
    const titles = JSON.parse(props.titles);
    const articleLabels = JSON.parse(props.articleLabels);

    const [selectedCategories, setSelectedCategories] = useState([]);
    const myRef = useRef(null);

    useEffect(() => {
        let searchUrl = window.location.search.toString();
        searchUrl.split('&').forEach(element => {
            updateSelectedCategories(false, decodeURIComponent(element).split('=')[1]);
        });
    }, []);

    const host = `search/${language}`;
    const searchkit = new SearchkitManager(host, {
        timeout: 10000
    });

    searchkit.addDefaultQuery(query => {
        query = query.setSort('App_Models_Article_short_article_name');
        return query.addQuery(
            BoolMust(
                TermsQuery(
                    "App_Models_Article_categories",
                    subCategories.map(category => category.name)
                )
            )
        );
    });

    let translations = {};

    if (language === 'fr') {
        translations = {
            'facets.view_less': 'Afficher moins',
            'facets.view_all': 'Afficher plus',
            'facets.view_more': 'Afficher plus',
            'pagination.previous': 'Précédente',
            'pagination.next': 'Suivante',
            "NoHits.NoResultsFound": "Aucun résultat trouvé pour {query}",
            "NoHits.NoResultsFoundDidYouMean": "Aucun résultat trouvé pour {query}. Voulez-vous dire {suggestion}?",
            "NoHits.DidYouMean": "Recherchez un autre  {query} à {suggestion}",
            "NoHits.SearchWithoutFilters": "Chercher autre {query} sans filtres",
            "NoHits.Error": "Désolé, une erreur s'est produite lors de l'obtention de vos résultats. Réessayez plus tard.",
            "NoHits.ResetSearch": "Réinitialiser le résultat de la recherche",
        };
    } else {
        translations = {
            'facets.view_less': 'Toon minder',
            'facets.view_all': 'Toon meer',
            'facets.view_more': 'Toon meer',
            'pagination.previous': 'Vorige',
            'pagination.next': 'Volgende',
            "NoHits.NoResultsFound": "Geen resultaten gevonden voor {query}",
            "NoHits.NoResultsFoundDidYouMean": "Geen resultaten gevonden voor {query}. Bedoelt u {suggestion}?",
            "NoHits.DidYouMean": "Zoek in plaats van {query} naar {suggestion}",
            "NoHits.SearchWithoutFilters": "Zoek naar {query} zonder filters",
            "NoHits.Error": "Sorry, er is een fout opgetreden bij het ophalen van uw resultaten. Probeer het later opnieuw.",
            "NoHits.ResetSearch": "Reset zoekresultaat",
        };
    }

    searchkit.translateFunction = key => translations[key];

    const updateSelectedCategories = (active, label) => {
        let filterObject = subCategories.filter((category) => Object.values(category)[1] === label);
        let filterId = _map(filterObject, (value, key) => {
            return Object.values(value)[0];
        });

        if (active === false) {
            setSelectedCategories(prevSelectedCategories => [...prevSelectedCategories, filterId[0]]);
        } else {
            setSelectedCategories(prevSelectedCategories => prevSelectedCategories.filter(category => category !== filterId[0]));
        }
    }

    const RefinementOption = props => {
        return (
            <div key={props.itemKey}>
                <div onClick={props.onClick}
                     className="input-group input-group__checkables"
                >
                    <div>
                        <input className="d-none" type="checkbox"
                               onChange={() => {
                               }}
                               checked={props.active}/>
                        <label
                            className={props.active === true ? "product-checkbox my-5 is-active" : "product-checkbox my-5"}
                            htmlFor="">
                            <div className="product-checkbox__label">
                                {props.label}
                            </div>
                            <div className="product-checkbox__count">
                                {props.count}
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        )
    };

    const CategoryRefinementOption = props => {
        return (
            <div key={props.itemKey}>
                <div onClick={props.onClick}
                     className="input-group input-group__checkables"
                >
                    <div>
                        <input className="d-none" type="checkbox"
                               onChange={() => {
                               }}
                               checked={props.active}/>
                        <label
                            onClick={() => {
                                updateSelectedCategories(props.active, props.label);
                            }}
                            className={props.active === true ? "product-checkbox my-5 is-active" : "product-checkbox my-5"}
                            htmlFor="">
                            <div className="product-checkbox__label">
                                {props.label}
                            </div>
                            <div className="product-checkbox__count">
                                {props.count}
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        )
    };

    const renderCategories = () => {
        return <RefinementListFilter
            field='App_Models_Article_categories'
            title={`<div class="text--h4 font-weight-bold">${category}</div>`}
            id={`App_Models_Article_categories`}
            itemComponent={CategoryRefinementOption}
            operator={'OR'}
            size={99}
            containerComponent={<SkPanel collapsable={true}
                                         defaultCollapsed={false}/>}/>
    }

    const renderProperties = () => {
        const allFilters = filters
            .map((filter) => {
                return filter.filters;
            })
            .flat();

        const activeFiltes = filters
            .filter((filter) => selectedCategories.includes(filter.key))
            .map((filter) => {
                return filter.filters;
            })
            .flat();

        let list = {};
        _foreach(allFilters, (value, key) => {
            if (!list.hasOwnProperty(Object.keys(value)[0])) {
                list = {...list, ...value};
            }
        })

        return _map(list, (value, key) => {
            let isActive = false;
            _foreach(activeFiltes, (activeList) => {
                if (activeList.hasOwnProperty(key)) {
                    isActive = true;
                }
            });

            let property = '';
            if (Object.keys(rangeSliderFields).includes(value.name)) {
                property = <RangeFilter id={`article_${key}`}
                                        title={`<div class="text--h4 font-weight-bold">${value.name}</div>`}
                                        field={`App_Models_Article_${key}`}
                                        rangeComponent={SKRangeSliderHistogramInput}
                                        min={rangeSliderFields[value.name].min}
                                        max={rangeSliderFields[value.name].max != rangeSliderFields[value.name].min
                                            ? rangeSliderFields[value.name].max
                                            : rangeSliderFields[value.name].max + 1}
                                        containerComponent={<SkPanel collapsable={true}
                                                                     defaultCollapsed={false}/>}
                />
            } else {
                property = <RefinementListFilter id={`article_${key}`}
                                                 title={`<div class="text--h4 font-weight-bold">${value.name}</div>`}
                                                 field={`App_Models_Article_${key}`}
                                                 operator={'OR'}
                                                 size={5}
                                                 itemComponent={RefinementOption}
                                                 containerComponent={<SkPanel collapsable={true}
                                                                              defaultCollapsed={false}/>}
                />
            }

            return (
                <div key={key + value} className="my-30">
                    <div className={isActive ? 'd-block' : 'd-none'}>
                        {property}
                    </div>
                </div>
            )
        })
    }

    return (
        <SearchkitProvider searchkit={searchkit}>
            <div className="row">
                <div className="col-product-filter" ref={myRef}>
                    <SideBar>
                        <div>
                            <HideOnInitialLoad>
                                <div className="text--h3 font-weight-bold">
                                    {titles.filters_title}
                                </div>
                            </HideOnInitialLoad>
                            <div className="my-30">
                                {renderCategories()}
                                {renderProperties()}
                            </div>
                        </div>
                    </SideBar>
                </div>

                <div className="col-product-content">
                    <div className="text--h3 font-weight-bold mb-20">
                        <HitsStats translations={{
                            "hitstats.results_found": `${titles.products_title} ({hitCount})`
                        }}/>
                    </div>
                    <div>
                        <Hits className="row" hitsPerPage={16} itemComponent={<ArticleTile labels={articleLabels} />}/>
                    </div>
                    <SKPagination
                        showNumbers={true}
                        showLast={true}
                        pageScope={1}
                    />
                </div>
            </div>
        </SearchkitProvider>
    );
}
