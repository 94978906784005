import React from "react";
import {Pagination} from "searchkit";
import {Paginator} from "../Helpers/PaginationHelper";

const get = require('lodash/get');

export class SKPagination extends Pagination {
    getTotalPages() {
        return Math.ceil(
            get(this.getResults(), 'hits.total.value', 1) / get(this.getQuery(), 'query.size', 10)
        )
    }

    setPage(page) {
        const pageNumber = this.normalizePage(page);
        if (this.isDisabled(pageNumber)) {
            return;
        }

        if (pageNumber === this.getCurrentPage()) {
            return // Same page, no need to rerun query
        }

        this.accessor.state = this.accessor.state.setValue(pageNumber);
        this.searchkit.performSearch()
    }

    getPages() {
        const { showNumbers, pageScope, showText, showLast} = this.props;
        const currentPage = this.getCurrentPage();
        const totalPages = this.getTotalPages();

        const builder = Paginator.build({
            showNumbers,
            showEllipsis: showText && totalPages > 3,
            showFirst: showText,
            showLast: showLast,
            showPrevious: showText && currentPage > 1,
            showNext: showText && currentPage < totalPages
        });

        return builder(currentPage, totalPages, this.translate, pageScope);
    }
}
