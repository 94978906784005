import * as React from "react";
import {InitialLoader, renderComponent} from "searchkit";

export class HideOnInitialLoad extends InitialLoader {

    render() {
        if (!this.isInitialLoading()) {
            return this.props.children
        }
        if (!this.props.component) {
            return null
        }
        return renderComponent(this.props.component, {
            bemBlocks: this.bemBlocks
        })
    }
}
